<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Components Overview</h1>
              <div class="pagehead-link"></div>
            </div>
            <p>
              The smallest functional elements or patterns that have value to
              the user.
            </p>
          </div>
        </section>

        <section>
          <div class="content-band">
            <div class="flex align-center mb-30">
              <h3>All Components</h3>
              <div class="p-5 plr-10 mlr-20  rounded-4 white">
                {{ components.length }}
              </div>
            </div>

            <div class="flex-grid flex-wrap gap-20 max-w1000 capitalize">
              <SmallTile
                v-for="(component, index) in components"
                :key="index"
                :tileName="component"
                route="components"
              />
            </div>
          </div>
        </section>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TempLeftNav from "../components/TempLeftNav";
import SmallTile from "../components/SmallTile";
import SubFooter from "../components/SubFooter";
export default {
  name: "ComponentsOverview",
  data: function() {
    return {
      components: [
        "accordions",
        "alerts",
        "buttons",
        "card",
        // "carousel",
        "checkbox",
        // "date-picker",
        "dividers",
        "dropdown",
        "list",
        "modal",
        "radio-button",
        "stepper",
        "text-input",
        "text-area",
        // "spinners",
        "tiles",
        "tabs",
      ],
    };
  },
  components: {
    TempLeftNav,
    SmallTile,
    SubFooter,
  },
};
</script>
